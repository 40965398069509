<template>
    <div>
        <ShStepTwo
            kind="chat"
            :kindtext="$lang.pages.compartirChat.chat"
            :loading="loading"
            :validation-status="validationStatus"
            @makePost="makePost"
            @setValidations="setValidations"
        />
    </div>
</template>

<script lang="ts" setup>
import encodeUrl from 'encodeurl'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'
const { buildHeaders, baseURL, endpoints } = useApiConfig()
import type { Api } from '~~/global'

definePageMeta({ middleware: ['auth-required'] })

const loading = ref(false)

const validationStatus = reactive({
    title: false as boolean | string,
    description: false as boolean | string,
    forum_category_id: false as boolean | string,
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()
const { $lang } = useNuxtApp()

const title = $lang.pages.compartirChat.title_seo
const description = $lang.pages.compartirChat.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const Router = useRouter()
const RootStore = useRootStore()
const AuthStore = useAuthStore()

const setValidations = (
    name: 'title' | 'description' | 'forum_category_id',
    status: boolean | string = false,
) => {
    validationStatus[name] = status
}
const makePost = async ({ title, description, forumCategoryId }: any) => {
    loading.value = true

    const newChat = { title, description, forum_category_id: forumCategoryId }

    const response = await $fetch<Api.Responses.Actions.SuccessChat | Api.Responses.Actions.FailedChat>(
        endpoints.create.foros.path,
        {
            method: 'POST',
            body: newChat,
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
        },
    ).catch((e) => e.data)

    if (!response) {
        RootStore.setSiteNotification({
            duration: 5000,
            text: $lang.pages.compartirChat.error_on_create,
            type: 'error',
        })
    } else if (
        response.feedback === 'error_fields_validation' ||
        response.feedback === 'params_validation_failed'
    ) {
        validationStatus.title = response.data.title?.[0] || false
        validationStatus.description = response.data.description?.[0] || false
        validationStatus.forum_category_id = response.data.forum_category_id?.[0] || false
    } else if (response.feedback === 'resource_created') {
        Router.push({
            path: `/compartir/finalizado`,
            query: {
                variant: 'chat',
                revision: 'false',
                slug: response.data.forum.slug,
                title: encodeUrl(response.data.forum.title),
            },
        })
    } else if (response.feedback === 'resource_needs_approval') {
        Router.push({
            path: `/compartir/finalizado`,
            query: {
                variant: 'chat',
                revision: 'true',
                title: encodeUrl(response.data.forum.title),
            },
        })
    }
    loading.value = false
}
</script>
